* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $scale;
}

body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    color: var(--text-color);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.layout-container {
    background: var(--body-bg);
    min-height: 100vh;
    &:before {
        min-height: 100vh;
        height: 100%;
        width: 100%;
        content: "";
        z-index: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-image: var(--body-image);
        background-repeat: no-repeat;
        z-index: 0;
    }

    .layout-content-wrapper {
        position: relative;
        z-index: 1;
        overflow-x: hidden;
    }
}
