/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";


.login-image img {
    object-fit: cover;
}

.layout-sidebar .layout-menu li {
    margin-top: 10px;
}

.layout-sidebar .layout-menu ul a fa-icon {
    margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody>tr {
    height: 40px;
}

.p-datatable .p-datatable-tbody>tr>td {
    cursor: pointer;
}

.p-paginator .p-dropdown {
    height: 2rem;
}

.layout-topbar .topbar-menu li.topbar-item ul {
    bottom: -4.4rem;
}

.p-confirm-dialog {
    background: var(--surface-ground);
}


.joint-badge.state-opened {
    background: var(--green-200);
    color: var(--green-800);
}

.joint-badge.state-finished {
    background: var(--pink-200);
    color: var(--pink-800);
}

.joint-badge.state-tagged {
    background: var(--yellow-200);
    color: var(--yellow-800);
}

.joint-badge.state-no-activity {
    background: var(--blue-200);
    color: var(--blue-800);
}

.p-toast-message-content {
    background: #334 !important;
}