.layout-rightmenu-button {
    background: linear-gradient(271.89deg, rgba(70,82,104,1), rgba(115,130,161,1));
    border-radius: 6px 0px 0px 6px;
    border-color: transparent;
    border-left: 0 none;
    box-shadow: 0px 0px 10px rgb(15 139 253 / 25%);
    right: 0;
    color: #fff;
    span {
        font-weight: 400 !important;
    }
    &:enabled:hover {
        background: linear-gradient(271.89deg, rgba(70,82,104,0.5), rgba(115,130,161,0.5));
        border-color: transparent;
    }
}
.layout-topbar {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topbar-start {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
        .topbar-menubutton {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            border-radius: var(--border-radius);
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            transition: background-color var(--transition-duration);

            i {
                font-size: 1.25rem;
                color: var(--text-color);
                transition: color var(--transition-duration);
            }

            &:hover {
                background-color: var(--primary-color);

                i {
                    color: var(--primary-color-text);
                }
            }
        }
    }

    .topbar-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 0 1.15rem 0 0.85rem;
        padding-right: 0;
        list-style: none;
        flex-grow: 1;
        color: var(--topbar-item-text-color);
        margin-right: -2rem;

        li {
            margin-left: 1.5rem;
            &.topbar-item {
                margin-left: 1.5rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: relative;

                img {
                    width: 2rem;
                    height: 2rem;
                }

                button {
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    transition: box-shadow var(--transition-duration);

                    &:focus {
                        box-shadow: var(--focus-ring);
                    }
                }

                ul {
                    position: absolute;
                    bottom: -9.4rem;
                    right: 0;
                    display: none;
                    color: var(--text-color);
                    background: var(--surface-ground);
                    box-shadow: var(--box-shadow);
                    &.active-topbar-menu {
                        display: block;
                    }

                    a {
                        color: var(--text-color);
                    }


                   
                }
            }
            .topbar-search {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                .topbar-searchbutton {
                   display: flex;
                }
        
                .search-input-wrapper {
                    position: relative;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
        
                    span {
                        width: 100%;
        
                        .p-inputtext {
                            width: 100%;
                            position: relative;
                            border-radius: 40px;
                            padding: 9px;
                        }
        
                        i {
                            font-size: 18px;
                            margin-top: -9px;
                        }
                    }
                }
        
                &.topbar-search-active {
                    .topbar-searchbutton {
                        opacity: 0;
                        display: none;
                        pointer-events: none;
                        cursor: default;
                        transition: opacity .1s;
                        transition-delay: .1s;
                    }
                    
                    .search-input-wrapper {
                        width: 190px;
                        opacity: 1;
                        visibility: visible;
                        border-radius: var(--border-radius);
                        i {
                            display: block;
                        }
                        input{
                            border-radius: var(--border-radius);
                        }
                    }
                }
            }

            button {
                span {
                    color: var(--item-text-color);
                }
            }
          
        }
    }
}

